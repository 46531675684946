<template>
    <div class="full-page image">
        <div class="container w-55r fl-eq" style="min-height: 24rem;">
            <div class="bg-primary" style="width: 50%;">
                <img class="mt-3 p-8" src="../../../assets/img/logo-w.png" alt="logo">
            </div>
            <div class="p-5 bg-2" style="width: 50%;">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LoginLayout'
};
</script>

<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image {
    background: url("../../../assets/img/banner-blurred.png");
}

.link {
    cursor: pointer;
}
</style>
