<template>
    <login-layout>
        <h5 class="text-primary mb-4">Reset Password</h5>
            <s-form @submit="changePassword" ref="form">
                <validated-input placeholder="Enter Old Password" type="password" name="old_password"
                                 v-model="model.old_password" class="text-secondary"
                                 :disabled="loading" :rules="rules.old_password"/>
                <validated-input placeholder="Enter New Password" name="new_password" type="password"
                                 v-model="model.new_password" class="text-secondary"
                                 :disabled="loading" :rules="rules.old_password"/>
                <validated-input placeholder="Confirm New Password" type="password" name="confirm_password"
                                 v-model="model.confirm_password" class="text-secondary"
                                 :disabled="loading" :rules="rules.confirm_password"/>
                <div class="text-center mt-4">
                    <btn class="pl-5 pr-5" text="Reset" loading-text="Processing..." size="sm"
                         :disabled="loading" :loading="loading"/>
                </div>
            </s-form>
    </login-layout>
</template>

<script>
import urls from '@/data/urls';
import LoginLayout from './LoginPageLayout';
import axios from 'secure-axios';

export default {
    name: 'ChangePassword',
    components: { LoginLayout },
    data () {
        return {
            loading: false,
            model: {
                old_password: '',
                new_password: '',
                confirm_password: ''
            },
            rules: {
                old_password: {
                    required: true
                },
                new_password: {
                    required: true
                },
                confirm_password: {
                    required: true
                }
            }
        };
    },
    methods: {
        async changePassword () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.setPassword, that.model);
            const json = response.data;
            if (json.success === true) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        formSuccess () {
            this.$notify('Changed Successfully', 'Success', {
                type: 'success'
            });
            this.$router.push('/app/');
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        }
    }
};
</script>
